import { useMemo, useState } from "react"
import styled from "styled-components"

import { Badge, Checkbox, useMediaQuery } from "@material-ui/core"

import { DeviceSettingsDialog } from "src/components/DeviceSettingsDialog/DeviceSettingsDialog"
import {
  NoSensors,
  NoSensorsOnFilter,
} from "src/components/EmptyState/NoSensors"
import { FilterQueryBox } from "src/components/Filter/FilterQueryBox"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { breakpoint } from "src/constants/breakpoints"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import {
  LowBatteryFilter,
  OfflineDeviceFilter,
} from "src/data/filters/filtersDevice"
import { HOME_MAX_LIMIT } from "src/data/homes/logic/homeConstants"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useUrlParamBoolean } from "src/hooks/useUrlParam"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MainView } from "src/ui/Layout/MainView"
import { spacing } from "src/ui/spacing"

import { DeviceTable, IDeviceWithHome } from "./DeviceTable"

const MOBILE_LAYOUT = breakpoint.normalDown

export function DeviceList() {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const orgId = org.id
  const [showSettings, setShowSettings] = useState(false)

  const renderStackedRows = useMediaQuery(`(${MOBILE_LAYOUT})`)

  const { limit, offset, setOffset } = useUrlPager()
  const { value: offline } = useUrlParamBoolean(OfflineDeviceFilter.id)
  const { value: low_battery } = useUrlParamBoolean(LowBatteryFilter.id)

  const fetchDevices = useFetchDevices({
    orgId,
    filter: { limit, offset, offline, low_battery },
    options: { keepPreviousData: false },
  })
  const deviceCountTotal = fetchDevices.data?.paging.total_count ?? 0

  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: {
      ids: fetchDevices.data?.devices?.map((d) => d.home),
      limit: HOME_MAX_LIMIT,
    },
    options: { enabled: !!fetchDevices.data?.devices },
  })

  const devicesPage: IDeviceWithHome[] = useMemo(() => {
    const devicesWithHome: IDeviceWithHome[] =
      fetchDevices.data?.devices.map((device) => {
        const home = fetchHomes.data?.homes.find(
          (h) => h.home_id === device.home
        )
        return {
          ...device,
          homeObj: {
            home_id: device.home,
            name: home?.name ?? "",
            timezone: home?.timezone || "etc/utc", // remove the hardcoding of timezone when we move this to a hook
          },
        }
      }) ?? []

    return devicesWithHome
  }, [fetchDevices.data?.devices, fetchHomes.data?.homes])

  const [selectedDevices, setSelectedDevices] = useState<IDeviceWithHome[]>([])
  const selectedDeviceIds = useMemo(
    () => new Set(selectedDevices.map((d) => d.device_id)),
    [selectedDevices]
  )

  function onCheckboxClick(device: IDeviceWithHome, checked: boolean) {
    if (checked) {
      setSelectedDevices((prev) => Array.from(new Set([...prev, device])))
    } else {
      setSelectedDevices((prev) => {
        return prev.filter((d) => d.device_id !== device.device_id)
      })
    }
  }

  const headers = useMemo(() => {
    const allSelected =
      !!devicesPage.length &&
      devicesPage.every((d) => selectedDeviceIds.has(d.device_id))

    function onSelectAll(checked: boolean) {
      if (checked) {
        setSelectedDevices((prev) =>
          Array.from(new Set([...prev, ...devicesPage]))
        )
      } else {
        setSelectedDevices((prev) => {
          const unselectCurrentPage = prev.filter(
            (selectedDevice) =>
              !devicesPage.find((d) => d.device_id === selectedDevice.device_id)
          )
          return unselectCurrentPage
        })
      }
    }

    return [
      <div key={"selected"}>
        <Checkbox
          checked={allSelected}
          onClick={() => onSelectAll(!allSelected)}
        />
      </div>,
      <div key={"sensor"}>{t(langKeys.sensor)}</div>,
      <div key={"home"}>{t(langKeys.home)}</div>,
      <div key={"signal"}>{t(langKeys.signal)}</div>,
      <div key={"battery"}>{t(langKeys.battery)}</div>,
      <div key={"status"}>{t(langKeys.status)}</div>,
      <div key={"mounting-plate"}>{t(langKeys.mounting_plate)}</div>,
    ]
  }, [devicesPage, langKeys, selectedDeviceIds, t])

  const disableDeviceSettingsButton = selectedDevices.length === 0
  const variant = disableDeviceSettingsButton ? "subtle" : "primary"

  const hasDevices = !!fetchDevices.data?.paging?.total_count
  const filtersApplied = !!offset || offline || low_battery
  const showNoSensors =
    !fetchDevices.isFetching && !filtersApplied && !hasDevices
  const showNoSearchResults =
    filtersApplied && !fetchDevices.isFetching && !devicesPage.length

  return (
    <MainView
      title={t(langKeys.sensor, { count: 2 })}
      titleBarProps={{
        actionBar: (
          <ActionMenu>
            {selectedDeviceIds.size > limit && (
              <MButton onClick={() => setSelectedDevices([])} variant="minimal">
                {t(langKeys.placeholder_web, {
                  text: "Clear all selected sensors",
                })}
              </MButton>
            )}
            <Badge badgeContent={selectedDeviceIds.size}>
              <MButton
                onClick={() => setShowSettings(true)}
                disabled={disableDeviceSettingsButton}
                variant={variant}
                hidden={renderStackedRows}
              >
                {t(langKeys.settings)}
              </MButton>
            </Badge>
          </ActionMenu>
        ),
      }}
      size="unset"
    >
      <DeviceSettingsDialog
        open={showSettings}
        onClose={() => setShowSettings(false)}
        devices={selectedDevices}
      />
      <FilterQueryBox
        dropdownFilters={[LowBatteryFilter, OfflineDeviceFilter]}
      />

      <DeviceTable
        linkBasePath="sensors"
        selectedDeviceIds={selectedDeviceIds}
        onCheckboxClick={onCheckboxClick}
        headers={headers}
        data={devicesPage || []}
        loading={fetchDevices.isLoading}
        expectedNbrRows={limit}
      />

      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={deviceCountTotal}
        />
      </PagerWrapper>
      {showNoSensors && <NoSensors />}
      {showNoSearchResults && <NoSensorsOnFilter />}
    </MainView>
  )
}

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const ActionMenu = styled.div`
  margin-left: auto;
  display: flex;
  gap: ${spacing.M};
`
