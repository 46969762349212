import { Fragment, useMemo } from "react"
import styled from "styled-components"

import { Checkbox } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { MountStatus } from "src/components/Devices/MountStatus"
import { BatteryAndChargeStatusIcon } from "src/components/Homes/DeviceDetails/BatteryAndChargeStatusIcon"
import { OnlineStatus } from "src/components/Homes/DeviceDetails/OnlineStatus"
import { Device } from "src/components/Homes/HomeDetails/Overview/Devices"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { GridTable, TGridCell } from "src/ui/GridTable/GridTable"
import { NetworkIcon } from "src/ui/icons/NetworkIcon"
import { InternalLink } from "src/ui/Link/InternalLink"

export function DeviceTable({
  headers,
  data,
  linkBasePath,
  selectedDeviceIds,
  onCheckboxClick,
  loading,
  expectedNbrRows,
}: {
  headers: TGridCell[]
  data: IDeviceWithHome[]
  linkBasePath: string
  selectedDeviceIds: Set<string>
  onCheckboxClick: (device: IDeviceWithHome, checked: boolean) => void
  loading: boolean
  expectedNbrRows: number
}) {
  const rows = data.map((device) => {
    const {
      device_id: deviceId,
      description,
      homeObj: home,
      battery,
      offline,
      charge_status: chargeStatus,
      mount_status: mountStatus,
      hardware_version: hardwareVersion,
    } = device

    const checked = !!selectedDeviceIds.has(deviceId)
    const deviceType = getDeviceHardwareType(hardwareVersion).type

    return (
      <Fragment key={deviceId}>
        <div>
          <Checkbox
            checked={checked}
            onClick={() => {
              onCheckboxClick(device, !checked)
            }}
          />
        </div>

        {home ? (
          <InternalLink to={`${linkBasePath}/${deviceId}`}>
            {description}
          </InternalLink>
        ) : (
          <div>{description}</div>
        )}

        <InternalLink to={`homes/${home.home_id}`}>
          {home.name || "Go to home"}
        </InternalLink>

        <div>
          <NetworkIcon device={device} />
        </div>

        <div>
          <BatteryBox>
            <BatteryAndChargeStatusIcon
              percent={battery?.percent}
              chargeStatus={chargeStatus}
              offline={offline}
              width={30}
            />
          </BatteryBox>
        </div>

        <div>
          <OnlineStatus online={!device.offline} />
        </div>

        <div>
          <MountStatus
            status={mountStatus}
            deviceType={deviceType}
            offline={offline}
            width={30}
            height={30}
          />
        </div>
      </Fragment>
    )
  })

  const mobileRows = data.map((deviceData) => {
    return (
      <Device
        key={deviceData.device_id}
        data={deviceData}
        timezone={deviceData.homeObj.timezone}
      />
    )
  })
  const skeletonRows = useMemo(
    () => createSkeletonRows(expectedNbrRows),
    [expectedNbrRows]
  )

  return (
    <TableBox>
      <GridTable
        header={headers}
        rows={loading ? skeletonRows : rows}
        mobileRows={mobileRows}
        templateColumns={`auto auto minmax(150px, 1fr) repeat(${
          headers.length - 3
        }, auto)`}
      />
    </TableBox>
  )
}

export interface IDeviceWithHome extends TDevice {
  homeObj: Pick<IHome, "name" | "home_id"> & {
    timezone: IHome["timezone"]
  }
}

function createSkeletonRows(nbrRows: number): JSX.Element[] {
  const createSkeletonRow = (key: string | number) => (
    <Fragment key={key}>
      <div>
        <Checkbox disabled />
      </div>
      <div>
        <Skeleton
          variant="text"
          width={`${Math.floor(Math.random() * 40) + 60}%`}
        />
      </div>
      <div>
        <Skeleton
          variant="text"
          width={`${Math.floor(Math.random() * 40) + 60}%`}
        />
      </div>
      <div>
        <Skeleton variant="rect" width="24px" />
      </div>
      <div>
        <Skeleton variant="rect" width="24px" />
      </div>
      <div>
        <Skeleton variant="rect" width="24px" />
      </div>
      <div>
        <Skeleton variant="rect" width="24px" />
      </div>
    </Fragment>
  )

  return Array.from(Array(nbrRows)).map((_, index) => createSkeletonRow(index))
}

const BatteryBox = styled.div`
  width: max-content;
`

const TableBox = styled.div`
  width: 100%;
  overflow-x: auto;
`
